function init() {
	let mouseX = 0
	let mouseY = 0
	
	// Window Stencil Background in mystuff
	let stencilForeground = document.getElementById('stencil-foreground')
	
	if (stencilForeground) {
		let clone = stencilForeground.cloneNode(true) as HTMLElement
		clone.removeAttribute('id')
		clone.classList.add("window-stencil-background", "window-bg-shadow")
		
		let parent = stencilForeground.parentElement
		
		if (parent != undefined) {
			parent.removeChild(stencilForeground)
			parent.appendChild(clone)
			parent.appendChild(stencilForeground)
		}
	}

	// // Slug Expander in Start window
	// let slugExpander = document.getElementById('slugexpander')
	// let seaSlugs = document.getElementById('seaslugs')
	
	// slugExpander?.addEventListener('click', e => {
	// 	seaSlugs?.classList.toggle('invisible')
	// })
	
	// Bounce in Contact
	let bounceBtn = document.getElementById('bouncy') as HTMLDivElement
	
	bounceBtn?.addEventListener('click', e => {
		if (!shouldBounceFrameLoop) {
			bounceBtn.innerText = "Stop it"
			
			lastTimestamp = performance.now()
			bounceVelX = -800
			bounceVelY = 0
			bounceWindow = document.getElementsByClassName('contact')[0] as HTMLElement
			
			let box = bounceWindow.getBoundingClientRect()
			bounceWindow.style.setProperty('--x-pos', `${box.x}px`)
			bounceWindow.style.setProperty('--y-pos', `${box.y}px`)
			bounceWindow.classList.add('window-bounce')
			
			shouldBounceFrameLoop = true
			requestAnimationFrame(bounceFrame)
		}
	})
	
	window.addEventListener('mouseup', e => {
		if (shouldBounceFrameLoop) {
			bounceBtn.innerText = "Do that Bouncy thing"
			shouldBounceFrameLoop = false
		}
	})
	
	// Open the Stuff window
	let theStuffButton = document.getElementById('thestuff')
	let theStuff = document.getElementsByClassName('thestuff')[0] as HTMLElement
	
	if (theStuffButton) {
		console.log('aaa')
		theStuffButton.addEventListener('click', () => {
			if (theStuff.classList.contains('invisible')) {
				// random magic numbers :3
				let x = window.innerWidth / 2 - 544 / 2
				let y = window.innerHeight / 2 - 602 / 2
				
				theStuff.style.setProperty('--x-pos', `${x}px`)
				theStuff.style.setProperty('--y-pos', `${y}px`)
				theStuff.classList.remove('invisible')
			} else {
				theStuff.classList.add('invisible')
			}
		})
	}
	
	// Close the Stuff window
	let theStuffOkBtn = document.getElementById('thestuff_ok') as HTMLDivElement
	
	theStuffOkBtn?.addEventListener('click', () => {
		theStuff.classList.add('invisible')
	})
	
	// // Close Contact window
	// let contact = document.getElementById('contact') as HTMLElement
	// let contactOkBtn = document.getElementById('contact_ok') as HTMLDivElement
	// contactOkBtn.addEventListener('click', e => {
	// 	console.log(window.location)
	// 	contact.classList.add('invisible')
	// 	if (window.location.hash === "#contact") {
	// 		window.location.assign('#aboutme')
	// 	}
	// })
	
	// // Settings
	// let wrench = document.getElementById('wrench') as HTMLElement
	// let settingsPanel = document.getElementById('settingsPanel') as HTMLDivElement
	// 
	// wrench.addEventListener('click', e => {
	// 	settingsPanel.classList.remove('invisible')
	// })
	// 
	// let closeSettingsBtn = document.getElementById('closeSettings') as HTMLElement
	// 
	// closeSettingsBtn.addEventListener('click', e => {
	// 	settingsPanel.classList.add('invisible')
	// })
	
	// Drag behavior
	let windows = document.getElementsByClassName('window')

	let dragWindow: HTMLElement | undefined
	
	for (const windowElement of windows) {
		let title = windowElement.children[0] as HTMLDivElement
		
		title.addEventListener('mousedown', e => {
			dragWindow = windowElement as HTMLElement
			// @ts-ignore
			windowElement.baseDrag = [mouseX, mouseY]
		})
	}
	
	window.addEventListener('mousemove', e => {
		mouseX = e.clientX
		mouseY = e.clientY
		
		if (dragWindow) {
			let windowX = parseInt(dragWindow.style.getPropertyValue('--x-pos').slice(0, -2))
			let windowY = parseInt(dragWindow.style.getPropertyValue('--y-pos').slice(0, -2))
			
			if (isNaN(windowX))
				windowX = 15
			if (isNaN(windowY))
				windowY = 15
			
			// @ts-ignore
			let [dragX, dragY] = dragWindow.baseDrag
			let dragOffset = [mouseX - dragX, mouseY - dragY]
			
			dragWindow.style.setProperty('--x-pos', `${windowX + dragOffset[0]}px`)
			
			if (mouseY > 15)
				dragWindow.style.setProperty('--y-pos', `${Math.max(windowY + dragOffset[1], 0)}px`)
			else
				dragWindow.style.setProperty('--y-pos', `0px`)
			
			// @ts-ignore
			dragWindow.baseDrag = [mouseX, mouseY]
		}
	})
	
	window.addEventListener('mouseup', e => {
		if (dragWindow) {
			dragWindow = undefined
			
			e.preventDefault()
		}
	})
}

let lastTimestamp = 0
let bounceVelX = 0
let bounceVelY = 0
let bounceWindow: HTMLElement
let shouldBounceFrameLoop = false

function bounceFrame(now: number) {
	let x = parseInt(bounceWindow.style.getPropertyValue('--x-pos').slice(0, -2))
	let y = parseInt(bounceWindow.style.getPropertyValue('--y-pos').slice(0, -2))
	
	let delta = (lastTimestamp - now) / 1000
	lastTimestamp = now
	
	x += bounceVelX * delta
	y += bounceVelY * delta
	
	bounceVelY += 6000 * delta
	
	let rect = bounceWindow.getBoundingClientRect()
	
	if (y + rect.height > window.innerHeight) {
		bounceVelY = -bounceVelY * 0.932
		y = window.innerHeight - rect.height - 1
	}
	
	if (x + rect.width > window.innerWidth) {
		bounceVelX = -bounceVelX
		x = window.innerWidth - rect.width
	}
	
	if (x < 0) {
		bounceVelX = -bounceVelX
		x = 0
	}
	
	bounceWindow.style.setProperty('--x-pos', `${x}px`)
	bounceWindow.style.setProperty('--y-pos', `${y}px`)
	
	if (shouldBounceFrameLoop)
		requestAnimationFrame(bounceFrame)
}

init()
